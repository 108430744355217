<script>
import { defineComponent, reactive } from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useErrors } from "@/compositions/useErrors";
import Swal from "@/plugins/swal";

export default defineComponent({
  name: "TeamShow",
  setup() {
    const router = useRouter();
    const store = useStore();
    const errors = useErrors();
    const state = reactive({
      team: {
        name: "",
        description: ""
      }
    });

    async function storeTeam() {
      errors.errorSet([]);
      try {
        const data = await store.dispatch("team/storeTeam", state.team);

        Swal.fire({
          title: "Success!",
          text: "Team created successfully!",
          icon: "success",
          confirmButtonText: "Cool"
        });

        await router.push({
          name: "dashboard.team.show",
          params: { id: data.id }
        });
      } catch (e) {
        errors.errorSet(e.errors);
      }
    }

    return {
      team: state.team,
      storeTeam,
      ...errors
    };
  }
});
</script>

<template>
  <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
    <h2 class="text-lg font-medium mr-auto">
      Create New Team
    </h2>
  </div>
  <div class="pos intro-y grid grid-cols-12 gap-5 mt-5">
    <!-- BEGIN: Post Content -->
    <div class="intro-y col-span-12 lg:col-span-8 space-y-2">
      <div :class="{ 'has-error': errorHas('name') && !errorHas('teams_count') }">
        <input
          v-model="team.name"
          type="text"
          class="input input--lg border w-full pr-10 placeholder-theme-13"
          placeholder="Name"
        >
        <span
          v-if="errorHas('name') && !errorHas('teams_count')"
          class="pristine-error text-theme-6 mt-2"
        >{{ errorGet("name") }}.
        </span>
      </div>
      <div :class="{ 'has-error': errorHas('description') && !errorHas('teams_count') }">
        <textarea
          v-model="team.description"
          class="input input--lg border w-full pr-10 placeholder-theme-13"
          placeholder="Description"
        />
        <span
          v-if="errorHas('teams_count')"
          class="block pristine-error text-theme-6 mt-2"
        >
          {{ errorGet("teams_count") }}.
        </span>
        <span
          v-else-if="errorHas('description')"
          class="pristine-error text-theme-6 mt-2"
        >{{ errorGet("description") }}.
        </span>
      </div>
      <button
        class="mt-4 button text-white bg-theme-1 shadow-md flex items-center"
        @click.prevent="storeTeam"
      >
        Create
      </button>
    </div>
    <!-- END: Post Content -->
  </div>
</template>

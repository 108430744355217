import {reactive} from 'vue';

const state = reactive({
    errors: {
        default: {}
    }
});

export function errorInitContainers(containers) {
    for(let i = 0; i < containers.length; i++) {
        state.errors[containers[i]] = {}
    }
}

export function errorSet(errors = {}, container = 'default') {
    return state.errors[container] = errors;
}

export function errorHas(field, container = 'default') {
    return Object.prototype.hasOwnProperty.call(state.errors[container], field)
}

export function errorGet(field, container = 'default') {
    return errorHas(field, container) ? state.errors[container][field][0] : '';
}

export function useErrors() {
    return {
        errorInitContainers,
        errorSet,
        errorHas,
        errorGet
    }
}